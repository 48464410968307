<div class="container-fluid">
    <div class="row">
        <div class="card col-lg-6 col-sm-12">
            <img class="card-img-top" src="assets/imgs/Home/WH1974.jpg" alt="Das Wanderheim im Jahre 1974">
            <div class="card-body">
                <h5 class="card-title">Pfälzerwald-Verein Hauenstein</h5>
                <div class="card-text">
                    <p>
                    Willkommen auf unserer Homepage <br>
                    Schauen Sie doch einfach mal wieder rein, vielleicht gibt es etwas Neues.
                    </p>
                    
                    <h5>50 Jahre „Dicke Eiche“, 50 neue Mitglieder </h5>
                    <p>Werden Sie 2024 Mitglied. Jedes Neumitglied in diesem Jahr erhält einen Verzehrgutschein über 5,00 € und 
                       nimmt an einer Verlosung (der Rechtsweg ist ausgeschlossen) mit tollen Preisen teil.
                    </p>
                        1. Preis Wanderschuhe-Gutschein im Wert 150 €<br>
                        2. Preis Wanderschuhe-Gutschein im Wert 100 €<br>
                        3. Preis Wanderschuhe-Gutschein im Wert 50 € <br>

                    <p>Mitgliedsantrag hier: <a href="assets/imgs/UeberUns/MGAntrag2021.pdf">Mitgliedsantrag</a>
                       oder in unserem Wanderheim.
                    </p>

                    <h5>Wanderheim</h5>
                    <p>Unsere <a href="https://www.pwv-hauenstein.de/wanderheim#restaurant" target=_blank> Öffnungszeiten</a>
                     Telefon Gaststätte: 06392 3596 
                    </p>   
                     
                    <h5>Übernachtungen buchen</h5>
                    <p>Ihre Anfrage und Buchung können Sie gerne per E-Mail richten an: 
                     <a _ngcontent-qpp-c12=""href="mailto:buchung@pwv-hauenstein.de">buchung@pwv-hauenstein.de</a><br>
                     Buchungs-Telefonnummer: +49 172 717 39 76<br>
                     <a href="https://www.gruppenhaus.de/plananzeige.php?hs=7784&anzmonate=12&sprache=de&colteil=%239400d3&colbelegt=%23ff0000&plannr=1" target=_blank>Belegungsplan</a>
                    </p>
                    <h5>Ehrenamt</h5>
                    <p>Sie wollen uns gerne unterstützen? Sowohl das Wanderheim "Dicke Eiche" mit Gaststätte und Übernachtungsbereich,
                        als auch das ganze Vereinsgeschehen wird von uns im Ehrenamt mit großem Engagement betrieben.
                    </p>    
                        Möglichkeiten zum Mitwirken: 
                        <ul>
                            <li>Hüttendienst</li>
                            <li>"Junge Familie"</li>
                            <li>Umwelt-/Naturschutz</li>
                            <li>Wanderbetrieb</li>
                            <li>Sie haben eigene Ideen zum Mitwirken?</li> 
                        </ul>                
                    <p>Es gibt viel zu tun. Sie wollen mit anpacken oder uns ideell unterstützen? 
                        Melden Sie sich unter <a href="mailto:info@pwv-hauenstein.de">info@pwv-hauenstein.de</a> <br>
                        Wir freuen uns auf Sie.   
                    </p>                                     
                      <h5>Auf dem Laufenden bleiben</h5>
                        Sie wollen auf dem Laufenden bleiben? Senden Sie eine E-Mail an: 
                        <a _ngcontent-qpp-c12=""href="mailto:waldnachrichten@pwv-hauenstein.de">waldnachrichten@pwv-hauenstein.de</a>
                </div>
           </div>
        </div>  
 
        <div class="card col-lg-6 col-sm-12">
            <img class="card-img-top" src="assets/imgs/Home/24Vorstand.jpg" alt="Der Vorstand">
            <div class="card-body">
                <h5 class="card-title">Aktuelles</h5>
                <div class="card-text">           
 
                 <h5>Neuer Vorstand</h5>   
                 <p>Auf dem Foto v.l.: Vorstand Finanzen, Markus Johann, Vorstand Wandern/aktives Vereinsleben, 
                    Michael Keiser, 
                    Vorständin Natur-/Umweltschutz, Vera Klemm, Vorstandsvorsitzender Johannes Scheib und 
                    Vorstand Hüttenbetrieb/Bauen/Liegenschaften Raymund Burkhard. In jedem Ressort, mit seinen vielfältigen 
                    und spezifischen Aufgaben, freuen wir uns über tatkräftige Unterstützung.
                 </p>                    
                 <p>
                   Raymund obliegen die Aufgaben rund um den Hüttenbetrieb.
                 </p>    
                    Du nutzt gerne unsere Angebote der "Dicken Eiche"? Hilf uns, dass wir den Betrieb in Vereinshand für alle Wanderfreundinnen 
                    und Wanderfreunde aufrecht erhalten können. Wir freuen uns über jede Hilfe in verschiedenen Belangen 
                    rund um unser Wanderheim. 
                 <p>
                    Da wären: 
                 </p>        
                    <ul>
                        <li>Hüttendienst: je nach deinen persönlichen Möglichkeiten und nach Absprache mit dir.</li>
                        <li>du hast Organistionstalent und würdest gerne den Hüttendienst organisieren und planen?</li>
                        <li>du wärst gerne verantwortlich oder Mit-Helfer*in für Einkäufe, Bestellungen oder die Warenannahme für den Hüttenbetrieb?</li>
                        <li>du liebst den direkten Kontakt und das Gespräch mit an-/abreisenden Übernachtungsgästen?</li>
                        <li>du magst lieber telefonieren und Verwaltungsaufgaben? </li> 
                        <li>du willst gerne dein handwerkliches Geschick unter Beweis stellen?</li>
                    </ul>                       
                    
                    Wir brauchen dich. Mail an: <a href="mailto: r.burkhardb@pwv-hauenstein.de"> r.burkhard@pwv-hauenstein.de</a>
                                    
                <div>
            </div>
        </div>
    </div>
</div>

